<template>
	<div class="eSkENu flex-wrap">
		<div class="header-box">
			<div class="header-left">
				<div class="left-menu"> <span class="el-icon-s-unfold" style="color: white;cursor: pointer;"
						@click="changeAside"></span> </div>
				<div class="blog-info">
					<div class="blog-title" title="Passer个人博客" @click="goAdmin">Mr. Li🌲</div>
					<div class="some-sentence">风花雪月皆耳闻,未若琉音少年人</div>
				</div>
				<div class="right-menu">
					<el-dropdown style='cursor: pointer;' @command='goOther'>
						<span class="el-dropdown-link">
							<i class="el-icon-arrow-down el-icon--right" style="color: white;"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item v-for="(item,index) in headerNavist" :icon="item.icon" :key='index'
								:command="item.path">
								{{item.val}}
							</el-dropdown-item>
							<el-dropdown-item divided command='share'>Share You</el-dropdown-item>
							<el-dropdown-item command="login" id='qqLoginBtn'>

							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
			<div class="header-right">
				<div class="tab-list">
					<el-link v-for="(item,index) in headerNavist" :icon="item.icon" :key='index' :underline='false'
						@click='goOther(item.path)'>{{item.val}}</el-link>
					<el-dropdown style='left: 48px;cursor: pointer;' @command='goScreen'>
						<span class="el-dropdown-link">
							<i class="el-icon-arrow-down el-icon--right" style="color: white;"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command='share'>Share You</el-dropdown-item>
							<el-dropdown-item command='screen'>全屏</el-dropdown-item>
							<el-dropdown-item command='love'>后续小故事^_^</el-dropdown-item>
							<!--PC端独有-->
							<el-dropdown-item command="login" divided id='qqLoginBtn2'>

							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		clientUserLogin
	} from '../../server/api/index.js';
	import {
		setUSERid
	} from '../../server/api/store.js';
	export default {
		props: {
			headerNavist: Array
		},
		data() {
			return {
				sideBar: false,
				isLogin: false, //判断是否登录
				nickname: '',
				figureurl: '',
				fullscreen: false,
			}
		},
		methods: {
			changeAside() {
				this.$emit('sideBar', !this.sideBar);
			},
			goOther(command) {
				if(command === 'share') return this.$router.push({path:'/share'})
				return this.$router.push({path: command});
			},
			goScreen(command) {                           //使页面进入全屏模式
				if (command === 'screen') {
					let element = document.documentElement;
					if (this.fullscreen) {
						if (document.exitFullscreen) {
							document.exitFullscreen();
						} else if (document.webkitCancelFullScreen) {
							document.webkitCancelFullScreen();
						} else if (document.mozCancelFullScreen) {
							document.mozCancelFullScreen();
						} else if (document.msExitFullscreen) {
							document.msExitFullscreen();
						}
					} else {
						if (element.requestFullscreen) {
							element.requestFullscreen();
						} else if (element.webkitRequestFullScreen) {
							element.webkitRequestFullScreen();
						} else if (element.mozRequestFullScreen) {
							element.mozRequestFullScreen();
						} else if (element.msRequestFullscreen) {
							// IE11
							element.msRequestFullscreen();
						}
					}
					this.fullscreen = !this.fullscreen;
				};
				if(command === 'love'){
					var val = window.prompt('请输入密码:');
					if(val === 'fhxyjew_wrlysnr'){
						alert('恭喜你^_^,玫瑰花的后续在等你呢');
						this.$router.push({path:'/zjx'});
					}else{
						alert('密码错误');
					}
				}
				if(command === 'share'){
					return this.$router.push({path:'/share'});
				}
			},
			goAdmin() {
				if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
					this.$myMessage({
						msg: '后台需PC端访问',
						icon: 'el-icon-error',
						color: 'color:red;',
						duration: 800
					});
				} else {
					let route = this.$router.resolve({
						path: '/admin'
					});
					window.open(route.href, '_blank');
				}
			},
			getScrollTop() { //监听鼠标滚动距离
				var scrollPos;
				if (window.pageYOffset) {
					scrollPos = window.pageYOffset;
				} else if (document.compatMode && document.compatMode != 'BackCompat') {
					scrollPos = document.documentElement.scrollTop;
				} else if (document.body) {
					scrollPos = document.body.scrollTop;
				}
				return scrollPos;
			},
		},
		mounted() {
			var that = this;
			window.onscroll = function() {
				var esKeNu = document.querySelector('.eSkENu');
				if (that.getScrollTop() >= 100) {
					esKeNu.style.top = -50 + 'px';
				} else {
					esKeNu.style.top = 0 + 'px';
				}
			};
			window.onresize = () => {
				// 全屏下监控是否按键了ESC
				if (!this.checkFull()) {
					// 全屏下按键esc后要执行的动作
					this.isFullscreen = false
				}
			};
			QC.Login({
				btnId: "qqLoginBtn", //插入按钮的节点id
				size: 'B_M'
			});
			QC.Login({
				btnId: "qqLoginBtn2", //插入按钮的节点id
				size: 'B_M'
			});
			if (QC.Login.check()) {
				QC.Login.getMe(function(openId, accessToken) {
					QC.api('get_user_info', {
						oauth_consumer_key: "101973618", // appid
						access_token: accessToken,
						openid: openId
					}).success(async function(res) {
						if (res.status === '200') {
							const {
								nickname,
								figureurl,
								figureurl_1
							} = res.data;
							//根据返回数据，更换按钮显示状态方法
							var dom = document.getElementById('qqLoginBtn'),
								_logoutTemplate = [
									//头像
									'<span><img src="{figureurl}"/></span>',
									//昵称
									'<span>{nickname}</span>',
									'<span><a href="javascript:QC.Login.signOut();">退出</a></span>'
								].join("");
							dom && (dom.innerHTML = QC.String.format(_logoutTemplate, {
								nickname: QC.String.escHTML(nickname), //做xss过滤
								figureurl: figureurl
							}));
							let ref = await clientUserLogin({
								nickname,
								figureurl_1
							});
							if (ref.code === 200) {
								setUSERid(JSON.stringify({
									nickname,
									figureurl_1
								})); //登录信息存入本地
								that.$myMessage({
									msg: ref.msg,
									icon: 'el-icon-success',
									color: 'color:green;',
									duration: 2000
								});
							} else {
								that.$message.error('登录失败');
							}
						}
					});
				});
				if (window.parent.name) {
					// 判断是否存在父窗口，如果存在就刷新父窗口，用于解决SDK默认打开授权窗口后重定向也在子                        窗口的问题
					// （登录成功的情况下，刷新父窗口自动关闭子窗口）
					opener.location.reload();
				}
			};
		}
	};
</script>

<style scoped="scoped" lang="less">
	@font-face {
		font-family: 'iconfont';
		/* Project id 2406555 */
		src: url('//at.alicdn.com/t/font_2406555_x7s845xrhth.woff2?t=1632924732007') format('woff2'),
			url('//at.alicdn.com/t/font_2406555_x7s845xrhth.woff?t=1632924732007') format('woff'),
			url('//at.alicdn.com/t/font_2406555_x7s845xrhth.ttf?t=1632924732007') format('truetype');
	}

	.eSkENu {
		user-select: none;
		z-index: 999;
		transition: all 0.4s ease 0s;
		position: relative;
		top: 0px;
		left: 0px;
		right: 0px;
		height: 50px;
		background-color: rgb(40, 54, 70);
	}

	.eSkENu2 {
		user-select: none;
		z-index: 999;
		transition: all 0.4s ease 0s;
		position: relative;
		top: -50px;
		left: 0px;
		right: 0px;
		height: 50px;
		background-color: rgb(40, 54, 70);
	}

	.flex-wrap {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-align-items: center;
		align-items: center;
	}

	.eSkENu .header-box {
		display: flex;
		width: 73%;
		height: 100%;
	}

	.eSkENu .header-box .header-left {
		height: 100%;
		width: 50%;
		display: flex;
		-webkit-box-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		align-items: center;
	}

	.eSkENu .header-box .header-right {
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		height: 100%;
		width: 50%;
	}

	.eSkENu .header-box .header-right .tab-list {
		width: 100%;
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		justify-content: space-around;
	}

	.eSkENu .header-box .header-left .blog-info {
		display: flex;
		-webkit-box-align: center;
		align-items: center;
	}

	.eSkENu .header-box .header-right .tab-list .el-link {
		color: #b9b5b5;
		font-size: 12px;
		margin-left: 60px;
		margin-right: -120px;
	}

	.eSkENu .header-box .header-right .tab-list .el-link:hover {
		color: white;
	}

	.eSkENu .header-box .header-left .blog-info .some-sentence {
		margin-top: 8px;
		margin-left: 10px;
		font-size: 12px;
		color: rgb(97, 97, 97);
	}

	.eSkENu .header-box .header-left .blog-info .blog-title {
		font-family: Arial, "Microsoft YaHei", '黑体', '宋体', sans-serif;
		cursor: pointer;
		font-size: 22px;
		color: yellow;
		font-weight: 500;
	}

	.eSkENu .header-box .header-left .left-menu {
		display: none;
	}

	.eSkENu .header-box .header-left .right-menu {
		display: none;
	}

	@media not screen and (min-width: 60em) {
		.eSkENu .header-box {
			width: 100%;
		}
	}

	@media not screen and (min-width: 50em) {
		.eSkENu .header-box .header-left {
			padding-left: 0px;
			width: 100%;
			justify-content: space-around;
		}
	}

	@media not screen and (min-width: 50em) {
		.eSkENu .header-box .header-right {
			display: none;
		}
	}

	@media not screen and (min-width: 50em) {
		.eSkENu .header-box .header-left .left-menu {
			display: block;
		}
	}

	@media not screen and (min-width: 50em) {
		.eSkENu .header-box .header-left .blog-info {
			// flex-direction: column;
			-webkit-box-pack: center;
			justify-content: center;
		}
	}

	@media not screen and (min-width: 50em) {
		.eSkENu .header-box .header-left .right-menu {
			display: block;
		}
	}

	@media not screen and (min-width: 50em) {
		.eSkENu .header-box .header-left .blog-info .some-sentence {
			display: none;
		}
	}

	@media not screen and (min-width: 50em) {
		.eSkENu .header-box .header-left .left-menu {
			display: block;
		}
	}
</style>
