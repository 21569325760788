<template>
	<div class="mobileSidebar">
		<div style="height: 100%;overflow-y: scroll;">
			<div rotate='360' class="sc-jRQAMF jhTJpR">
				<div class="fixed_info">
					<img :src="theme===true?sidebarInfo[0].avatar:'https://lmf401647220.oss-cn-beijing.aliyuncs.com/my_blog2022/1660713696549.jpg'"
						class="my_avat" alt="头像" @click="fnChangeMode($event)">
					<div class="person_name">{{sidebarInfo[0].name}}</div>
					<div class="school_info">
						<div>{{sidebarInfo[0].professional}}</div>
						<div>{{sidebarInfo[0].time}}</div>
					</div>
					<div class="person_info">
						<div><span class="el-icon-location"></span> {{sidebarInfo[0].position}}</div>
						<div>{{sidebarInfo[0].client}}</div>
						<div>{{sidebarInfo[0].admin}}</div>
						<div>{{sidebarInfo[0].server}}</div>
						<div><span class="el-icon-message"></span> {{sidebarInfo[0].email}}</div>
						<div class="dubai">有很多想去的地方</div>
						<el-divider><span style="color: rgb(24, 144, 255)">社交账号</span></el-divider>
						<div class="concat_ways">
							<div>
								<span class="iconfont" style="font-size: 30px;">&#xe882;</span>
							</div>
							<div>
								<span class="iconfont" style="font-size: 30px;">&#xe600;</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="information" style="text-align: center;">
				<div style="color: white;">访客信息</div>
				<div style="color: white;font-size: 12px;">欢迎来自 <span
						style="color: rgb(24,144,255);">{{$store.state.positon.pro}}-{{$store.state.positon.city}}</span>
					的访问者</div>
				<div style="color: white;font-size: 12px;">您于 <span
						style="color: rgb(24,144,255);">{{$store.state.positon.dateNow}}</span> 访问</div>
				<div style="color: white;font-size: 12px;">ip <span
						style="color: rgb(24,144,255);">119.39.43.33</span>(密)</div>
			</div>
			<div class="sc-bqiQRQ hUhAjW">
				<h3 style="color: white;font-size: 16px;">友情链接</h3>
				<el-button type="danger" size='mini'
					style='background-color: rgb(255,124,170);color: white;margin-top: 15px;'>申请友链</el-button>
			</div>
			<div class="sc-gKckTs hSDKox2">
				<div class="title">
					<span style="color: rgb(24,144,255);">标签云</span>
					<span class="el-icon-cloudy" style="font-size:20px;"></span>
				</div>
				<div class="tag_list">
					<div class="sc-iCfLBT eOsQzd" v-for="(item,index) in tagsCategory">
						<span class="tag_name" :style="item.color">{{item.tag_name}}<span
								style="margin-left: 4px;"></span></span>
						<span class="triangle" :style="item.color2"></span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		clientTagsCategory
	} from '../../server/api/index.js'
	export default {
		props: {
			sidebarInfo: Array,
		},
		data() {
			return {
				tagsCategory: [],
				theme: false,
			}
		},
		methods: {
			async initTags() {
				let ref = await clientTagsCategory();
				if (ref.code === 200) {
					this.tagsCategory = ref.data;
				}
			},
			fnChangeMode($event) {
				if (!this.theme) {
					window.document.documentElement.setAttribute('data-theme', 'dark')
					$event.target.style = 'transform: rotate(360deg)';
				} else {
					window.document.documentElement.setAttribute('data-theme', 'light')
					$event.target.style = 'transform: rotate(0deg)';
				}
				return this.theme = !this.theme;
			}
		},
		mounted() {
			this.initTags();
		}
	}
</script>

<style scoped="scoped">
	@font-face {
		font-family: 'iconfont';
		/* Project id 2406555 */
		src: url('//at.alicdn.com/t/font_2406555_pjm447lmq7q.woff2?t=1633420794785') format('woff2'),
			url('//at.alicdn.com/t/font_2406555_pjm447lmq7q.woff?t=1633420794785') format('woff'),
			url('//at.alicdn.com/t/font_2406555_pjm447lmq7q.ttf?t=1633420794785') format('truetype');
	}

	.jhTJpR {
		padding: 15px 10px;
		text-align: center;
	}

	.hUhAjW {
		padding: 10px 20px;
		text-align: center;
	}

	.hSDKox2 {
		animation: 0.7s ease 0s 1 normal none running moveUp;
		padding: 10px;
	}

	.jhTJpR .my_avat {
		width: 100px;
		height: 100px;
		border-radius: 50%;
		transition: all 0.5s linear 0s;
		transform: rotate(360deg);
		animation: 2s ease 0s infinite normal none running shadow;
	}

	.jhTJpR .person_name {
		padding: 7px 0px;
		font-size: 19px;
		font-weight: 600;
		color: rgb(24, 144, 255);
	}

	.jhTJpR .school_info {
		font-size: 13px;
		color: rgb(17, 158, 130);
		padding: 5px 0px;
	}

	.jhTJpR .person_info {
		color: rgb(127, 127, 127);
		font-size: 13px;
	}

	.jhTJpR .dubai {
		font-weight: bold;
		font-family: 楷体;
		font-size: 15px;
		padding-top: 10px;
		color: rgb(24, 144, 255);
	}

	/deep/.el-divider__text {
		background-color: rgb(40, 54, 70);
	}

	/deep/.el-divider {
		background-color: #34373d;
	}

	.jhTJpR .concat_ways {
		display: flex;
		justify-content: space-around;
		color: rgb(24, 144, 255);
	}

	.hSDKox2 .title {
		font-size: 17px;
		color: rgb(45, 183, 245);
		padding-bottom: 10px;
	}

	.hSDKox2 .tag_list {
		display: flex;
		flex-wrap: wrap;
	}

	.eOsQzd {
		user-select: none;
		cursor: pointer;
		z-index: 999;
		margin-left: 10px;
		margin-top: 8px;
		position: relative;
	}

	.eOsQzd .tag_name {
		border-radius: 3px;
		transition: all 0.4s ease 0s;
		right: 0px;
		color: rgb(53, 120, 119);

		padding: 0px 4px;
		font-size: 15px;
	}

	.eOsQzd .triangle {
		transition: all 0.4s ease 0s;
		top: 50%;
		left: -3px;
		width: 6px;
		height: 6px;
		border-width: 0px 0px 1px 1px;
		z-index: 9;
		border-style: solid;
		background-color: rgb(40, 54, 70);
		transform: rotate(45deg) translate(-50%, -50%);
		position: absolute;
	}

	.mobileSidebar {
		background-color: rgb(40, 54, 70);
		padding-top: 30px;
	}

	@keyframes shadow {
		0% {
			box-shadow: rgb(255 255 255) 0px 0px 1px, rgb(255 255 255) 0px 0px 5px, rgb(255 255 255) 0px 0px 10px, purple 0px 0px 20px, purple 0px 0px 5px, pink 0px 0px 11px;
		}

		,
		100% {
			box-shadow: rgb(255 255 255) 0px 0px 5px, rgb(255 255 0) 0px 0px 10px, rgb(255 255 0) 0px 0px 15px, green 0px 0px 8px, green 0px 0px 10px, rgb(0 0 255) 0px 0px 12px;
		}
	}
</style>
