<template>
	<div id="home">
		<Skeleton v-show="isShowLoading" /> <!-- 骨架屏 -->
		<div v-if="!isShowLoading">
			<homeHeader @sideBar='getSideBarData' :headerNavist='headerNavist'></homeHeader><!-- 页面头部 -->
			<homeMain :articleData='articleData' :total='total' :sidebarInfo='sidebarInfo'></homeMain> <!-- 页面主干 -->
			<homeFooter></homeFooter> <!-- 页面底部 -->

			<el-drawer size='60%' :with-header="false" :visible.sync="childSideBar" :direction="direction"
				:wrapperClosable='true'>
				<mobileSidebar :sidebarInfo='sidebarInfo'></mobileSidebar>
			</el-drawer>

			<el-dialog :title="tip.title_tip" :visible.sync="dialogVisible" width="35%" :before-close="handleClose">
				<span>
					<p>{{tip.update_time}}</p>
					<p style="margin-top: 10px;margin-bottom: 10px;">{{tip.yiji_content}}</p>
					<el-image :src="tip.main_image"></el-image>
					<div style="color: gray;font-size: 10px;margin-top: 5px;">
						{{tip.erji_content}}
					</div>
				</span>
				<span slot="footer" class="dialog-footer">
					<el-button @click="tipDialog('pass')">略过</el-button>
					<el-button type="primary" @click="tipDialog('thank')">谢谢</el-button>
				</span>
			</el-dialog>
			<el-backtop></el-backtop>
		</div>
	</div>
</template>
<script>
	import {
		mapState
	} from 'vuex';
	import {
		clientHomeHeaderNavilst,
		clientHome,
		clientHomeSiderInfo,
		clientGetTip
	} from '../server/api/index.js';
	import homeHeader from './clinet/homeHeader.vue'; //引入头步视图组件
	import homeMain from './clinet/main.vue'; //引入页面主干
	import homeFooter from './clinet/Footer.vue'; //引入页面底部
	import Skeleton from './clinet/skelekon.vue'; //引入骨架屏
	import mobileSidebar from './clinet/mobileSidebar.vue'; //引入移动端侧边栏
	export default {
		data() {
			return {
				direction: 'ltr',
				childSideBar: false, //接收子组件传来的值
				headerNavist: null, //给子组件传值
				articleData: null,
				isShowLoading: true,
				total: null,
				sidebarInfo: null,
				dialogVisible: true,
				tip: []
			}
		},
		methods: {
			getSideBarData(data) { //接收子组件传来的值
				this.childSideBar = data;
			},
			isInitYingHua(){
				if (!(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent))) {
						let script = document.createElement("script"); //创建一个script标签
						script.type = 'text/javascript';
						script.src = 'https://cdn.jsdelivr.net/gh/yremp/yremp-js@1.5/sakura.js';
						document.head.appendChild(script);
				}
			},
			async init(page) {
				let ref = await clientHomeHeaderNavilst();
				let ref2 = await clientHome({
					page
				});
				let ref3 = await clientHomeSiderInfo();
				let ref4 = await clientGetTip();
				if (ref.code === 200 && ref2.code === 200 && ref3.code === 200 && ref4.code === 200) {
					this.headerNavist = ref.data;
					this.sidebarInfo = ref3.data;
					ref2.data.forEach((val) => {
						val.tags = JSON.parse(val.tags).tagData; //数据类型转换
						val.tags.forEach((val) => {
							val.color = 'background-color:' + val.color; //属性值转换
						})
					});
					this.total = ref2.length;
					this.articleData = ref2.data;
					this.tip = ref4.data[0];
					this.isShowLoading = false;
				}
			},
			handleClose(done) {
				this.$confirm('确认关闭？')
					.then(_ => {
						done();
					})
					.catch(_ => {});
			},
			tipDialog(val) {
				if (val !== 'pass') {
					this.$myMessage({
						msg: '谢谢^_^',
						icon: 'el-icon-success',
						color: 'color:green;',
						duration: 2000
					});
				}
				this.dialogVisible = false;
			},
			loadScriptString(code) {
				var script = document.createElement("script"); //创建一个script标签
				script.type = "text/javascript";
				try {
					//IE浏览器认为script是特殊元素,不能再访问子节点;报错;
					script.appendChild(document.createTextNode(code));
				} catch (ex) {
					script.text = code;
				}
				document.getElementById('home').appendChild(script);
			},
			ribbon() {
				var html = `var canvas = document.createElement('canvas'),
	g2d = canvas.getContext('2d'),
	pr = window.devicePixelRatio || 1,
	width = window.innerWidth,
	height = window.innerHeight,
	f = 40,
	q, t,
	m = Math,
	r = 0,
	pi = m.PI * 2,
	cos = m.cos,
	random = m.random;
canvas.width = width * pr;
canvas.height = height * pr;
g2d.scale(pr, pr);
g2d.globalAlpha = 0.8;
canvas.style.cssText = 'opacity: ' + 0.8 + ';position:fixed;top:0;left:0;z-index: ' + -1 +
	';width:100%;height:100%;pointer-events:none;';
// create canvas
document.getElementsByTagName('body')[0].appendChild(canvas);

function redraw() {
	g2d.clearRect(0, 0, width, height);
	q = [{
		x: 0,
		y: height * 0.7 + f
	}, {
		x: 0,
		y: height * 0.7 - f
	}];
	while (q[1].x < width + f) draw(q[0], q[1]);
}

function draw(i, j) {
	g2d.beginPath();
	g2d.moveTo(i.x, i.y);
	g2d.lineTo(j.x, j.y);
	var k = j.x + (random() * 2 - 0.25) * f,
		n = line(j.y);
	g2d.lineTo(k, n);
	g2d.closePath();
	r -= pi / -50;
	g2d.fillStyle = '#' + (cos(r) * 127 + 128 << 16 | cos(r + pi / 3) * 127 + 128 << 8 | cos(r + pi / 3 * 2) * 127 +
		128).toString(16);
	g2d.fill();
	q[0] = q[1];
	q[1] = {
		x: k,
		y: n
	};
}

function line(p) {
	t = p + (random() * 2 - 1.1) * f;
	return (t > height || t < 0) ? line(p) : t;
}
document.onclick = redraw;
document.ontouchstart = redraw;
redraw();
`;
		 this.loadScriptString(html);
			}
		},
		mounted() {
			window.onfocus = function() {
				document.title = "嘿嘿，你回来啦~~~^_^";
			};
			window.onblur = function() {
				document.title = "呜呜，不要走啊!!!-_-";
			};
			this.isInitYingHua();
			window.onload = function() {
					var a_idx = 0;

					function randomColor() {
						var color = "#";
						//for循环中，如果后面仅有一条语句，{}可省略不写
						//随机生成6位0-15之间的数字，再用toString(16)将数字转成16进制
						for (var i = 0; i < 6; i++) color += parseInt(Math.random() * 16).toString(16);
						return color;
					}
					jQuery(document).ready(function($) {
						$("body").click(function(e) {
							var a = new Array("富强", "民主", "文明", "和谐", "自由", "平等", "公正", "法治", "爱国", "敬业",
								"诚信", "友善");
							var $i = $("<span/>").text(a[a_idx]);
							a_idx = (a_idx + 1) % a.length;
							var x = e.pageX,
								y = e.pageY;
							$i.css({
								"z-index": 999,
								"top": y - 20,
								"left": x,
								"position": "absolute",
								"font-weight": "bold",
								"color": randomColor()
							});
							$("body").append($i);
							$i.animate({
									"top": y - 150,
									"opacity": 0
								},
								800,
								function() {
									$i.remove();
								});
						});
					});
				},
				this.init(this.TruePage) //页面初始化
				this.ribbon();
		},
		components: {
			Skeleton,
			homeHeader,
			homeMain,
			homeFooter,
			mobileSidebar
		},
		computed: {
			...mapState(['TruePage'])
		}
	}
</script>
<style scoped="scoped">
	/deep/.el-dialog__title {
		line-height: 24px;
		font-size: 18px;
		color: red;
	}

	@media not screen and (min-width: 50em) {
		/deep/.el-dialog {
			width: 90% !important;
		}
	}
</style>
