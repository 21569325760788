<template>
	<div class="Maincontainer">
		<el-row>
			<el-col :span="14" :offset="3" class='mobileStyle'>
				<el-card class="TheMain" shadow='never'>
					<router-view name="default" :articleDataed='articleData' :totals='total'></router-view>
				</el-card>
			</el-col>
			<el-col :span="3" :offset="1">
				<div class="TheSidebar">
					<div class="jhTJpR">
						<img :src="theme===true?sidebarInfo[0].avatar:'https://lmf401647220.oss-cn-beijing.aliyuncs.com/my_blog2022/1660713696549.jpg' " alt="头像" class="my_avat" @mouseover="changeTheme($event)">
						<div class="person_name">{{sidebarInfo[0].name}}</div>
						<div class="school_info">
							<div>{{sidebarInfo[0].professional}}</div>
							<div>{{sidebarInfo[0].time}}</div>
						</div>
						<div class="person_info">
							<div> <span class="el-icon-location"></span> {{sidebarInfo[0].position}}</div>
							<div>{{sidebarInfo[0].client}}</div>
							<div>{{sidebarInfo[0].admin}}</div>
							<div>{{sidebarInfo[0].server}}</div>
							<div><span class="el-icon-message"></span> {{sidebarInfo[0].email}}</div>
							<div class="dubai">有很多想去的地方</div>
							<el-divider>社交帐号</el-divider>
							<div class="concat_ways">
								<div><span class="iconfont" style="font-size: 30px;cursor: pointer;">&#xe600;</span>
								</div>
								<div><span class="iconfont" style="font-size: 30px;cursor: pointer;">&#xe882;</span>
								</div>
							</div>
						</div>
						<div class="hRpQKF">
							<div class="your_words">
								相见恨晚 <span class="iconfont">&#xeca1;</span>
							</div>
							<div class="YourInfo">
								<div>此站昵称: <span>web前端赶路人</span></div>
								<div>您的地址: <span>{{$store.state.positon.pro}}-{{$store.state.positon.city}}</span></div>
								<div>详细地址:<span>{{$store.state.positon.detailAddress}}</span></div>
								<div>您好,现在是: <span>{{$store.state.positon.dateNow}} 。 累了一天了,我相信你一定会越来越强的!</span></div>
							</div>
						</div>
						<div class="sc-gKckTs hSDKox">
							<div class="title">
								<span style="color: rgb(24,144,255);">标签云</span>
								<span class="el-icon-cloudy"></span>
							</div>
							<div class="tag_list">
								<div class="sc-iCfLBT hwoWSZ" v-for="(item,index) in tagsCategory">
									<span class="tag_name" v-on:mouseover="mouseEnvent($event)"
										v-on:mouseleave="mouseEnvent2($event)"
										:style="item.color">{{item.tag_name}}<span
											style="margin-left: 4px;"></span></span>
									<span class="triangle" :style="item.color2"></span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import {
		clientTagsCategory
	} from '../../server/api/index.js'
	import {
		current
	} from '../../server/api/tool.js';
	export default {
		props: {
			articleData: Array,
			total: Number,
			sidebarInfo: Array,
		},
		data() {
			return {
				positon: {
					province: '',
					city: ''
				},
				tagsCategory: [],
				theme:false,
			}
		},
		methods: {
			getPosition() {
				var that = this;
				AMap.plugin('AMap.Geolocation', function() {
					new AMap.Geolocation({
						enableHighAccuracy: true, //是否使用高精度定位，默认:true
						timeout: 1000, //超过10秒后停止定位，默认：5s
						buttonPosition: 'RB', //定位按钮的停靠位置
						buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
						zoomToAccuracy: true, //定位成功后是否自动调整地图视野到定位点
					}).getCurrentPosition(function(status, result) {
						if (status !== 'complete') {
							that.$store.state.positon.pro = '获取失败,请检查游览器权限';
							that.$store.state.positon.city = '获取失败,请检查游览器权限';
							that.$store.state.positon.detailAddress = '获取失败,请检查游览器权限';
						};
						that.$store.state.positon.pro = result.addressComponent.province;
						that.$store.state.positon.city = result.addressComponent.city;
						that.$store.state.positon.detailAddress = result.formattedAddress;
						that.$store.state.positon.dateNow = current();
					});
				});
			},
			async initTags() {
				let ref = await clientTagsCategory();
				if (ref.code === 200) {
					this.tagsCategory = ref.data;
				}
			},
			randomColor() {
				var color = "#";
				//for循环中，如果后面仅有一条语句，{}可省略不写
				//随机生成6位0-15之间的数字，再用toString(16)将数字转成16进制
				for (var i = 0; i < 6; i++) color += parseInt(Math.random() * 16).toString(16);
				return color;
			},
			mouseEnvent($event) {
				return $event.currentTarget.style.backgroundColor = this.randomColor();
			},
			mouseEnvent2($event) {
				return $event.currentTarget.style.backgroundColor = '';
			},
			changeTheme($event){
			    if (!this.theme) {
					$event.target.style = 'transform: rotate(360deg)';
					window.document.documentElement.setAttribute('data-theme', 'dark')
				} else {
					window.document.documentElement.setAttribute('data-theme', 'light')
					$event.target.style = 'transform: rotate(0deg)';
				}
				return this.theme = !this.theme;
			},
		},
		mounted() {
			this.getPosition();
			this.initTags();
		},
	}
</script>

<style scoped="scoped">
	@font-face {
		font-family: 'iconfont';
		/* Project id 2406555 */
		src: url('//at.alicdn.com/t/font_2406555_exszbmz4rvs.woff2?t=1632984292118') format('woff2'),
			url('//at.alicdn.com/t/font_2406555_exszbmz4rvs.woff?t=1632984292118') format('woff'),
			url('//at.alicdn.com/t/font_2406555_exszbmz4rvs.ttf?t=1632984292118') format('truetype');
	}

	.hRpQKF {
		padding: 15px;
	}

	.hSDKox {
		animation: 0.7s ease 0s 1 normal none running moveUp;
		padding: 10px;
		text-align: left;
	}

	.hSDKox .title {
		font-size: 17px;
		color: rgb(45, 183, 245);
		padding-bottom: 10px;
	}

	.hRpQKF .your_words {
		font-size: 17px;
		border-radius: 4px;
		border-bottom: 1px solid rgb(24, 144, 255);
		text-align: left;
		color:rgb(24, 144, 255) ;
	}

	.hwoWSZ .triangle {
		transition: all 0.4s ease 0s;
		top: 50%;
		left: -3px;
		background-color: white;
		width: 6px;
		height: 6px;
		border-width: 0px 0px 1px 1px;
		z-index: 9;
		border-style: solid;
		transform: rotate(45deg) translate(-50%, -50%);
		position: absolute;

	}

	.hwoWSZ .tag_name {
		border-radius: 3px;
		transition: all 0.4s ease 0s;
		right: 0px;
		color: rgb(53, 120, 119);

		padding: 0px 4px;
		font-size: 15px;
	}

	.hSDKox .tag_list {
		display: flex;
		flex-wrap: wrap;
	}

	.Maincontainer {
		position: relative;
		padding-top: 10px;
	}

	.hRpQKF span {
		    color: rgb(47 159 177);
		    font-weight: 800;
	}

	.hSDKox .title {
		font-size: 17px;
		color: rgb(45, 183, 245);
		padding-bottom: 10px;
	}

	.hRpQKF .YourInfo {
		font-size: 14px;
		font-family: 楷体;
		color: rgb(133, 133, 133);
		padding-top: 10px;
		text-align: left;
	}

	.hRpQKF .YourInfo div {
		margin-top: 7px;
	}

	.hwoWSZ {
		user-select: none;
		cursor: pointer;
		z-index: 999;
		margin-left: 10px;
		margin-top: 15px;
		position: relative;
	}

	.TheMain {
		margin-left: 14px;
		box-shadow: 0 0px 0px 0 rgb(0 0 0 / 15%);
		margin-left: 14px;
	}

	/deep/ .TheMain .el-card__body {
		padding: 5px;
	}

	.TheSidebar {
		box-shadow: 0 0px 0px 0 rgb(0 0 0 / 15%);
		margin-left: -44px;
		margin-right: 17px;
		border: 1px solid #EBEEF5; 
	    background-color: #FFF;
		
		min-width: 200px;
		animation: 0.6s ease-in 0s 1 normal none running move_right;
		
		border-radius: 5px;
	}
    @keyframes move_right{
    	0% {
    	    transform: translateX(110%);
    	},
		50% {
		    transform: translateX(0px);
		},
		75% {
		    transform: translateX(10%);
		},
		100% {
		    transform: translateX(0px);
		}
    }
	.jhTJpR {
		padding: 15px 10px;
		text-align: center;
	}

	.my_avat {
		width: 100px;
		height: 100px;
		border-radius: 50%;
		transition: all 0.5s linear 0s;
		transform: rotate(0deg);
		cursor: pointer;
		animation: 2s ease 0s infinite normal none running shadow;
	}

	.person_name {
		padding: 7px 0px;
		font-size: 19px;
		font-weight: 600;
		color: rgb(24, 144, 255);
	}

	.school_info {
		font-size: 13px;
		color: rgb(17, 158, 130);
		padding: 5px 0px;
	}

	.person_info {
		color: rgb(127, 127, 127);
		font-size: 12px;
	}

	.person_info div {
		margin-bottom: 2px;
	}

	.dubai {
		font-weight: bold;
		font-family: 楷体;
		font-size: 15px;
		padding-top: 10px;
		color: rgb(24, 144, 255);
	}

	/deep/.el-divider__text {
		background-color: #fff;
		font-size: 14px;
		color: rgb(24, 144, 255);
	}

	.concat_ways {
		display: flex;
		justify-content: space-around;
		color: rgb(24, 144, 255);
		margin-top: 20px;
	}

	@media not screen and (min-width: 50em) {
		.TheSidebar {
			display: none;
		}
	}

	@media not screen and (min-width: 50em) {
		.mobileStyle {
			margin-left: -13px;
			width: 103%;
		}

		/deep/ .el-card__body {
			padding: 0px !important;
		}
	}

	@media not screen and (min-width: 50em) {
		/deep/ .el-pagination__jump {
			margin-left: 0px !important;
		}

		/deep/ .el-pagination {
			margin-top: 10px;
		}
	}

	@keyframes shadow {
		0% {
			box-shadow: rgb(255 255 255) 0px 0px 1px, rgb(255 255 255) 0px 0px 5px, rgb(255 255 255) 0px 0px 10px, purple 0px 0px 20px, purple 0px 0px 5px, pink 0px 0px 11px;
		}

		,
		100% {
			box-shadow: rgb(255 255 255) 0px 0px 5px, rgb(255 255 0) 0px 0px 10px, rgb(255 255 0) 0px 0px 15px, green 0px 0px 8px, green 0px 0px 10px, rgb(0 0 255) 0px 0px 12px;
		}
	}
</style>
