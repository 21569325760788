<template>
	<div id="detail" v-loading='loading'>
		<div class="detail_header">
			<div class="home" @click="goBack">首页 </div>
			<div> / {{articleData.title}}</div>
		</div>
		<div class="detail_all_info">
			<div class="detail_title">{{articleData.title}}</div>
			<div class="detail_info">
				<div class="time">
					<span class="el-icon-date" style="color: #20B2AA;"></span> {{articleData.birth_time}}
				</div>
				<div class="readingCount">
					<span class="el-icon-hot-water" style="color: red;"></span> {{articleData.hot}}
				</div>
				<div class="commentCount">
					<span class="el-icon-chat-dot-round"></span> {{articleData.remark_num}}
				</div>
			</div>
		</div>
		<div class="detail_image">
			<img :src="articleData.img_src" alt="文章图片" style="width: 100%;">
		</div>
		<el-divider> <span style="color:rgb(24, 144, 255);font-size: 18px;font-weight: 600;">description</span>
		</el-divider>
		<div style="color: rgb(107,106,106);padding: 10px;">{{articleData.end_text}}</div>
		<el-divider> <span style="color:rgb(24, 144, 255);font-size: 18px;font-weight: 600;">正文</span></el-divider>
		<div class="markdown-body" v-html="articleData.article_content"></div>
		<hr />
		<div class="article_tags">
			<div>
				<span class="el-icon-price-tag" style="font-size: 23px;color: #00BFFF;"></span>
				<span v-for="(item,index) in tags" class="tag_item" :style="item.color"
					:key='index'>{{item.name}}</span>
			</div>
			<div class="modifyTime">最后修改于:{{articleData.modify_time}}</div>
			<el-divider>
				<span class="iconfont"
					style="color: rgb(255,87,119);padding: 0px 10px;font-size: 30px;cursor: pointer;">&#xe784;</span>
				<span class="iconfont"
					style="color: rgb(27, 146, 255);padding: 0px 10px;font-size: 30px;cursor: pointer;">&#xe882;</span>
				<span class="iconfont"
					style="color: rgb(28, 214, 108);padding: 0px 10px;font-size: 30px;cursor: pointer;">&#xe600;</span>
			</el-divider>
		</div>
		<div class="comment_input_wrap">
			<div class="input_and_submit" style="text-align: right;">
				<hr class="parting-line">
				<div class="dsy_tip">
					可以在这里发表您的看法或则建议<span style="color: rgb(236,83,40);">(支持markdown语法)</span>
				</div>
				<el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="textarea">
				</el-input>
				<el-button type="primary" style='margin-top: 10px;' @click='releaseComment'>提交评论</el-button>
			</div>
			
			
			<!-- 以下为评论区代码 -->
			
			
			<div class="kszVAv">
				<div v-for="(item,index) in remarkData" :key='index'>
					<div class="fidTJP">
						<div class="ant-comment">
							<div class="ant-comment-inner">
								<div class="ant-comment-avatar">
									<span
										class="ant-avatar ant-avatar-circle ant-avatar-image avatar">
										 <el-avatar :src="item.avatar"></el-avatar>
										</span></div>
								<div class="ant-comment-content">
									<div class="ant-comment-content-author"><span class="ant-comment-content-author-name"><a
												href="#"
												style="font-size: 12px; color: rgb(255, 87, 119);text-decoration: none;	">{{item.author_name}}</a></span>
											   <span
											   class="ant-comment-content-author-time"><span><span style="color: gray;">{{item.remark_date}}</span>
											   <span style="margin-left: 5px;color: gray;">{{item.province}} - {{item.city}} </span></span></span></div>
									<div class="ant-comment-content-detail">
										<div class="markdown-body" style="margin-top: unset;">
											<p>{{item.remark}}</p>
										</div>
									</div>
									<ul class="ant-comment-actions">
										<li>
											<span>
												<span class="iconfont" isThum='false' @click="thumbsUp($event,item.id,'commentFather')">&#xec7f;</span>
												<span>{{item.star}}</span>
											</span>
										</li>
										<li>
											<span style="color: rgb(24,144,255);" @click="remarkCallback(item)">回复</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="children" style="margin-left: 40px;" v-if='item.id===item2.callbakID' v-for="(item2,index2) in callBackData" :key='index2'>
						<div class="fidTJp">
							<div class="ant-comment">
								<div class="ant-comment-inner">
									<div class="ant-comment-avatar">
										<span class="ant-avatar ant-avatar-circle ant-avatar-image avatar">
											 <el-avatar :src="item2.avatar"></el-avatar>
										</span>
									</div>
									<div class="ant-comment-content">
										<div class="ant-comment-content-author">
											<span class="ant-comment-content-author-name">
												<a href="#" style="font-size: 12px;color: rgb(255,87,119);text-decoration: none;">{{item2.author_name}}</a>
												<span class="upPerson">{{item2.status}}</span>
											</span>
											<span class="ant-comment-content-author-time">
												<span style="color: gray;">
													<span>{{item2.remark_date}}</span>
													<span style="margin-left: 5px;">{{item2.province}} - {{item2.city}}</span>
												</span>
											</span>
										</div>
										<div class="ant-comment-content-detail">
											<span  class="comment_father">@{{item2.beCallback}}:</span>
											<div class="markdown-body" style="margin-top: unset;">
												<p>{{item2.remark}}</p>
											</div>
										</div>
										<ul class="ant-comment-actions">
											<li>
												<span>
													<span class="iconfont" isThum='false' @click="thumbsUp($event,item2.id,'commentChildren')">&#xec7f;</span>
													<span class="comment-action">{{item2.star}}</span>
												</span>
											</li>
											<li>
												<span style="color: rgb(24,144,255);"></span><!-- 当前回复区只有两个层级 -->
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<p style="text-align: center;color: rgb(24,144,255);margin-top: 20px;cursor: pointer;">查看更多留言。。。</p>
		</div>
		<el-dialog
		  title="输入您想说的话~"
		  :visible.sync="dialogVisible"
		  width="30%"
		  :before-close="handleClose">
		  <span>
			  <el-input disabled :placeholder="callPerson"></el-input>
			  <el-input
			    type="textarea"
			    :rows="4"
			    placeholder="请随便说点话吧~~~"
			    v-model="textareaCallBack"
				style='margin-top: 10px;'
				>
			  </el-input>
			   <el-divider></el-divider>
		  </span>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible = false">取 消</el-button>
		    <el-button type="primary" @click="submitCallback">确 定</el-button>
		  </span>
		</el-dialog>
		<!-- <myMessage></myMessage> --> <!-- 组件的封装 -->
	</div>
</template>

<script>
	import 'highlight.js/styles/googlecode.css';
	import hljs from 'highlight.js';//导入代码高亮文件
	import {
		clientDetailArticle,
		clientUserRemark,
		clientInitRemarkContent,
		clientRemarkCallBack,
		clientThumb
	} from '../../server/api/index.js';
    import myMessage from '../../components/tool/myMessage.vue';
	import { getUSERid } from '../../server/api/store.js';
	import { current } from '../../server/api/tool.js';
	export default {
		data() {
			return {
				dialogVisible:false,
				currentPath: '', //当前的路由
				articleData: '', //文章详情数据
				loading: true,
				tags: [],
				textarea: '',
				articleID:'',
				textareaCallBack:'', //回复内容
				remarkData:'',//文章评论数据,评论表
				callBackData:'', // 回复表
				callPerson:'',//回复的人
				eachPersonData:'', //被回复人的数据
			}
		},
		methods: {
			async thumbsUp($event,id,data){
			    if($event.target.getAttribute('isThum')==='false'){
					$event.currentTarget.style='color:red;transform: scale(1.1);transition:0.8s;display:inline-block;'
					let ref = await clientThumb({type:'isThumb',node:data,text_id:this.articleID,remarkid:id});  //点赞
					if(ref.code===200){
						$event.target.setAttribute('isThum','true');
						this.$myMessage({msg:ref.msg,icon:'el-icon-success',color:'color:green;',duration:2000});
						this.initRemarkContent(); //评论区的刷新
					}
				}else{
					$event.currentTarget.style='color:;'
					let ref = await clientThumb({type:'cancelThumb',node:data,text_id:this.articleID,remarkid:id});  //取消点赞
				    if(ref.code===100){
						$event.target.setAttribute('isThum','false');
				    	this.$myMessage({msg:ref.msg,icon:'el-icon-error',color:'color:red;',duration:2000});
						this.initRemarkContent();  //评论区的刷新
				    }
				}
			},
			getPath() {
				this.articleID = this.$route.fullPath;
				this.currentPath = this.$route.path;
			},
			async init(currentPath) {
				let ref = await clientDetailArticle({
					path: currentPath
				});
				if (ref.code === 200) {
					this.articleData = ref.data[0];
					document.title = ref.data[0].title;
					JSON.parse(ref.data[0].tags).tagData.forEach((val) => {
						val.color = 'background-color:' + val.color; //属性值转换
						this.tags.push(val);
					});
					this.loading = false;
				}
			},
			async initRemarkContent(){
				let ref = await clientInitRemarkContent({text_id:this.articleID});
				if(ref.code===200){
					this.remarkData = ref.data;
					//此处应该找一个具体某个评论的ID
					this.callBackData = ref.data2;
				}
			},
			goBack() {
				return this.$router.go(-1); // 后退+刷新
			},
			async highlighthandle() {    //对代码高亮问题进行处理
				await hljs;
				let highlight = document.querySelectorAll('code,pre');
				highlight.forEach((block) => {
					hljs.highlightBlock(block);
				})
			},
			async releaseComment(){
				if(QC.Login.check()){
					if(this.textarea.length===0) return this.$myMessage({msg:'评论不能为空!',icon:'el-icon-error',color:'color:red;',duration:1000});
					let contentInfo = new Object();
					contentInfo.texteara = this.textarea; //评论内容
					contentInfo.avatar = getUSERid().figureurl_1; //头像
					contentInfo.name = getUSERid().nickname;//昵称
					contentInfo.status = '用户'; //身份
					contentInfo.province = this.$store.state.positon.pro; //省份
					contentInfo.city = this.$store.state.positon.city;  //城市
					contentInfo.time = current();  //当前时间
					contentInfo.articleID  = this.articleID;  //文章ID
					let ref = await clientUserRemark(contentInfo);
					if(ref.code===200){
						this.$myMessage({msg:ref.msg,icon:'el-icon-success',color:'color:green;',duration:2000});
						this.textarea = '';
						this.initRemarkContent(); //评论区刷新
					}else{
						this.$myMessage({msg:ref.msg,icon:'el-icon-error',color:'color:red;',duration:2000});
					}
				}else{
					this.$myMessage({msg:'请登录!方可评论',icon:'el-icon-error',color:'color:red;',duration:1500});
				}
			},
			remarkCallback(val){
			  this.eachPersonData = val;	
			  this.callPerson = '回复@'+ val.author_name;
			  this.dialogVisible = true;
			},
			async submitCallback(){
				if(QC.Login.check()){
					if(this.textareaCallBack.length===0) return this.$myMessage({msg:'评论不能为空!',icon:'el-icon-error',color:'color:red;',duration:2000});;
					let { author_name,text_id,id } = this.eachPersonData;  //回复的 人，文章，评论ID
					let contentInfo = new Object();
					contentInfo.texteara = this.textareaCallBack; //评论内容
					contentInfo.avatar = getUSERid().figureurl_1; //头像
					contentInfo.name = getUSERid().nickname;//昵称
					contentInfo.status = '用户'; //身份
					contentInfo.province = this.$store.state.positon.pro; //省份
					contentInfo.city = this.$store.state.positon.city;  //城市
					contentInfo.time = current();  //当前时间
					contentInfo.articleID  = text_id;  //文章ID
					contentInfo.beCallback = author_name;
					contentInfo.callBackID = id;
					let ref = await clientRemarkCallBack(contentInfo);
					if(ref.code===200){
						this.$myMessage({msg:ref.msg,icon:'el-icon-success',color:'color:green;',duration:2000});
						this.textareaCallBack = '';
						this.initRemarkContent(); //评论区刷新
					}else{
						this.$myMessage({msg:ref.msg,icon:'el-icon-error',color:'color:red;',duration:2000});
					}
				}else{
					this.$myMessage({msg:'请登录,方可回复!',icon:'el-icon-error',color:'color:red;',duration:2000});
				}
				this.dialogVisible = false;
			},
			handleClose(done) {
			    this.$confirm('确认关闭？').then(_ => {done();}).catch(_ => {});
			}
		},
		mounted() {
			this.getPath();
			this.init(this.currentPath); //文章页面初始化
			this.highlighthandle();
			this.initRemarkContent();
		},
		watch: {
			'$route': 'getPath' //路由监听
		},
		components:{
			myMessage
		}
	}
</script>

<style scoped="scoped">
	@font-face {
	  font-family: 'iconfont';  /* Project id 2406555 */
	  src: url('//at.alicdn.com/t/font_2406555_nisauljoqn.woff2?t=1632834453581') format('woff2'),
	       url('//at.alicdn.com/t/font_2406555_nisauljoqn.woff?t=1632834453581') format('woff'),
	       url('//at.alicdn.com/t/font_2406555_nisauljoqn.ttf?t=1632834453581') format('truetype');
	}
    /deep/ .el-input.is-disabled .el-input__inner {
    height: 35px;
    }
	/deep/ .el-dialog__footer {
   margin-top: -45px;
   }
   /deep/.el-textarea__inner{
   	 background: url('https://blog-1303885568.cos.ap-chengdu.myqcloud.com/useImg/comment.png') right bottom no-repeat !important;
   }
	.detail_header {
		background-color: rgb(225, 240, 255);
		padding: 10px 0px 10px 5px;
		display: flex;
		color: rgb(0, 121, 136);
		font-size: 16px;
		width: 102%;
		margin-left: -10px;
	}

	.detail_header .home {
		color: rgb(24, 144, 255);
		cursor: pointer;
		text-decoration: underline;
		font-weight: 600;
		padding-left: 15px;
	}

	.detail_all_info {
		padding: 0px 10px;
		text-align: center;
		width: 100%;
	}

	.detail_title {
		font-size: 28px;
		font-weight: 600;
		color: rgb(24, 144, 255);
		margin-top: 10px;
	}

	.detail_info {
		display: flex;
		padding: 20px 0px;
		-webkit-box-pack: center;
		justify-content: center;
		font-size: 13px;
	}

	.detail_info div {
		padding: 0px 10px;
	}

	.markdown-body {
		margin-top: 30px;
		padding: 0px 10px;
	}

	.article_tags {
		background-color: rgb(246, 248, 250);
		width: 100%;
		margin-top: 10px;
	}
    .fidTJp .upPerson {
        color: rgb(255, 97, 55);
        border: 1px solid rgb(255, 97, 55);
        font-size: 10px;
        padding: 1px 2px;
        margin-right: 2px;
        border-radius: 2px;
        font-weight: 600;
    }
	.article_tags .modifyTime {
		text-align: right;
		color: rgb(204, 204, 204);
		font-size: 15px;
		padding-right: 10px;
	}
     .el-divider__text {
        background-color: rgb(246, 248, 250);
        font-size: 14px;
        color: deeppink;
    }
	.article_tags .tag_item {
		margin-left: 6px;
		padding: 4px 10px;
		height: 60px;
		border-radius: 7px;
	}
    .markdown-body:after, .markdown-body:before {
        display: table;
        content: "";
    }
	.markdown-body:after {
	    clear: both;
	}
	.markdown-body:after, .markdown-body:before {
	    display: table;
	    content: "";
	}
	.markdown-body>:last-child {
	    margin-bottom: 0!important;
	}
	.comment_input_wrap .parting-line {
		height: 4px;
		border: 0px;
		background-color: rgb(221, 221, 221);
		margin: 20px 0px;
		background-image: repeating-linear-gradient(-45deg, rgb(255, 255, 255), rgb(255, 255, 255) 4px, transparent 0px, transparent 8px);
	}

	.comment_input_wrap {
		padding: 15px;
	}

	.comment_input_wrap .dsy_tip {
		padding: 5px 0px;
		text-align: center;
		font-size: 19px;
		font-weight: 600;
		color: rgb(153, 153, 153);
	}

	.kszVAv {
		overflow-x: hidden;
		padding: 0px 10px;
	}
    .fidTJp .comment_father {
        color: blue;
    }
	.fidTJp {
		display: block;
		transition: all 0.5s ease 0s;
		animation: 0.5s ease 0s 1 normal forwards running moveRight;
	}

	.ant-comment {
		position: relative;
		background-color: inherit;
	}

	.ant-comment-inner {
		display: flex;
		padding: 16px 0;
	}

	.ant-comment-avatar {
		position: relative;
		flex-shrink: 0;
		margin-right: 12px;
		cursor: pointer;
	}
    .ant-comment-actions>li>span {
        margin-right: 10px;
        color: rgba(0,0,0,.45);
        font-size: 12px;
        cursor: pointer;
        transition: color .3s;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
	.avatar {
		transition: all 0.5s ease 0s;
	}
    .ant-comment-actions>li {
        display: inline-block;
        color: rgba(0,0,0,.45);
    }
	.ant-comment-content {
		position: relative;
		flex: 1 1 auto;
		min-width: 1px;
		font-size: 14px;
		word-wrap: break-word;
	}

	.ant-comment-content-author {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin-bottom: 4px;
		font-size: 14px;
		margin-top: 5px;
	}

	.ant-comment-content-author>a,
	.ant-comment-content-author>span {
		padding-right: 8px;
		font-size: 12px;
		line-height: 18px;
	}
    .ant-comment-actions {
        margin-top: 12px;
        margin-bottom: inherit;
        padding-left: 0;
    }
	/deep/ .markdown-body .highlight pre,
	/deep/.markdown-body pre {
		background-color: #282c34;
		border-radius: 3px;
		position: relative;
	}

	/deep/ .markdown-body pre:before {
		background-color: #ed6a5e;
		left: 9px;
	}

	/deep/.markdown-body pre:after,
	/deep/ .markdown-body pre:before {
		content: "";
		position: absolute;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		top: 6px;
	}

	/deep/.markdown-body pre:after,
	.markdown-body pre:before {
		content: "";
		position: absolute;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		top: 6px;
	}

	/deep/.markdown-body pre:after {
		background-color: #f6bf4f;
		left: 25px;
	}

	/deep/.hljs:before {
		content: "";
		background-color: #65ca56;
		position: absolute;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		left: 42px;
		top: 6px;
	}

	/deep/ .hljs {
		display: block;
		padding: .1em;
		color: #abb2bf;
		font-size: 16px;
		background: #282c34;
	}

	/deep/.hljs-keyword,
	/deep/.hljs-selector-tag,
	/deep/ .hljs-section,
	/deep/.hljs-title,
	/deep/.hljs-name {
		color: #eddb59;
	}

	/deep/ .hljs-comment,
	/deep/ .hljs-quote {
		color: #5f5f5f;
	}

	/deep/.hljs-title,
	/deep/.hljs-doctag,
	/deep/.hljs-type,
	/deep/.hljs-attr,
	/deep/.hljs-built_in,
	/deep/.hljs-builtin-name,
	/deep/.hljs-params {
		color: #e340e3;
	}

	/deep/.hljs-string,
	/deep/.hljs-selector-attr,
	/deep/.hljs-selector-pseudo,
	/deep/.hljs-regexp {
		color: #84da84;
	}

	/deep/.hljs-literal,
	/deep/.hljs-symbol,
	/deep/.hljs-bullet,
	/deep/.hljs-meta,
	/deep/.hljs-number,
	/deep/.hljs-link {
		color: #13d2d2;
	}
</style>
