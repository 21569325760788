<template>
	<div class="kGvAFP">
		<MainBodySkeleton v-show="loading"></MainBodySkeleton>
		<div v-if="!loading">
			<div class="home_content_header">
				<span class="info">实战与生活 <span style="color: red;">10</span>篇</span>
				<el-input class='asdUDls' style='width: 150px;border-radius: 5px;color: rgb(122,122,122);margin-right: 15px;'
					suffix-icon="el-icon-search" size='mini' @keyup.enter.native="search" v-model="input1">
				</el-input>
			</div>
			<div class="production_list">
				<div class="sc-bkkfTU htSpxm battle0" v-for="(item,index) in combatData" :key='index'>
					<div class="production_top" :style="item.img">
						<div class="innerInfo">
							<div class="title">{{item.title}}</div>
							<div class="des">{{item.skill}}</div>
						</div>
					</div>
					<div class="foot">
						<span>
							<span class="el-icon-date" style="color: #00BFFF;"></span>
							{{item.time}}
						</span>
						<span>
							<span class="el-icon-price-tag" style="color: rgb(255, 0, 255);"></span>
							项目实战
						</span>
						<span>
							<span class="el-icon-user" style="color: lightseagreen;"></span>
							LiMengFei
						</span>
						<a class="link" :href="item.url" title='Vue个人博客'>
							<span class="el-icon-link" style="color: #0000FF;font-size: 16px;"></span>
						</a>
					</div>
				</div>
			</div>
		</div>
		
		<el-dialog title="查询结果" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
			<span> <el-link v-for="(item,index) in searchResult" :href="item.url" target="_self" type="primary" :key='index' style='display: block;'>{{item.title}}</el-link></span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">Cancel</el-button>
				<el-button type="primary" @click="dialogVisible = false">Ok</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		clientCombat,
		clientGetCombatSearch
	} from '../../server/api/index.js';
	import MainBodySkeleton from './mainbodySkeleton.vue';
	export default {
		data() {
			return {
				input1: '',
				combatData: [],
				loading: true,
				dialogVisible: false,
				searchResult:[],/*查询结果*/
				queryUrl:'',//路由地址
			}
		},
		methods: {
			async init() {
				let ref = await clientCombat();
				if (ref.code === 200) {
					this.combatData = ref.data;
					this.loading = false
				}
			},
			async search(){
				if (this.input1.length === 0) return this.$myMessage({msg:'查询字段不能为空!',icon:'el-icon-error',color:'color:red;',duration:2000});
				let ref = await clientGetCombatSearch({query:this.input1});
				if(ref.code===200){
					this.searchResult = ref.data;
					this.dialogVisible = true;
					this.input1 = '';
				}else{
				   this.searchResult = [ref.data];
				   this.dialogVisible = true;
				   this.input1 = '';
				}
			},
			handleClose(done) {
				this.$confirm('确认关闭？')
				.then(_ => {
					done();
				})
				.catch(_ => {});
			},
		},
		mounted() {
			this.init();
		},
		components: {
			MainBodySkeleton
		}
	}
</script>

<style scoped="scoped">
	.kGvAFP {
		padding: 15px 0;
	}

	.kGvAFP .home_content_header {
		padding: 0px 10px 5px;
		width: 100%;
		display: flex;
		-webkit-box-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		align-items: center;
	}

	.kGvAFP .home_content_header .info {
		color: rgb(86, 86, 86);
		font-weight: 600;
		font-size: 14px;
	}

	.htSpxm {
		padding: 0px;
		user-select: none;
		opacity: 1;
		animation: 1s ease 0s 1 normal forwards running css1;
		background-color: white;
		margin-top: 20px;
		border-radius: 20px;
		overflow: hidden;
	}

	.htSpxm .production_top {
		border-radius: 20px 20px 0px 0px;
		background-size: cover;
		background-position: center center;
		width: 100%;
		height: 288px;
	}

	.htSpxm .foot {
		padding: 8px 0px;
	}

	.htSpxm .production_top .innerInfo {
		transition: all 0.4s ease 0s;
		cursor: pointer;
		width: 100%;
		height: 100%;
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		flex-direction: column;
		-webkit-box-pack: center;
		justify-content: center;
		position: relative;
		color: white;
	}

	.htSpxm .production_top .innerInfo .title {
		width: 100%;
		text-align: center;
		transition: all 0.3s ease 0s;
		font-size: 35px;
		text-shadow: rgb(255 255 255) 0px 0px 8px;
	}

	.htSpxm .production_top .innerInfo:hover .title {
		transition: all 0.3s ease 0s;
		transform: translateY(-100%);
	}

	.htSpxm .production_top .innerInfo:hover .des {
		opacity: 1;
		bottom: 50%;
	}

	.htSpxm .production_top .innerInfo .des {
		padding: 0px 4px;
		word-break: break-word;
		transition: all 0.3s ease 0s;
		position: absolute;
		bottom: 0px;
		font-size: 18px;
		opacity: 0;
		transform: translateY(100%);
	}

	.htSpxm .foot .link {
		margin-left: 525px;
		color: blue;
	}

	.htSpxm .foot span {
		font-size: 13px;
		color: rgb(82, 82, 82);
		margin-left: 6px;
	}

	.production_top .innerInfo:hover {
		background-color: rgba(0, 0, 0, 0.4);
	}
    @media not screen and (min-width: 50em) {
    	.htSpxm .foot .link {
    	    margin-left:90px;
    	    color: blue;
    	}
    }
	@keyframes css1 {
		0% {
			opacity: 0;
			transform: translate3d(-100%, 0px, 0px);
		}

		,
		50% {
			opacity: 1;
			transform: translate3d(3%, 0px, 0px);
		}

		,
		65% {
			opacity: 1;
			transform: translate3d(-2.5%, 0px, 0px);
		}

		,
		80% {
			opacity: 1;
			transform: translate3d(0px, 0px, 0px);
		}

		,
		90% {
			opacity: 1;
			transform: translate3d(-1%, 0px, 0px);
		}

		,
		100% {
			opacity: 1;
			transform: translate3d(0px, 0px, 0px);
		}
	}
</style>
