<template>
	<div id="wrapeds">
		<MainBodySkeleton v-show="loading"></MainBodySkeleton>
		<div v-if="!loading">
			<div class="home_content_header">
				<span class="info">
					博客日志
					<span>{{totals}}</span>
					篇
				</span>
				<el-input placeholder="enter键即可查询" v-model="input" maxlength='15' clearable class='searchInput' size='mini'
					@keyup.enter.native="startQuery">
				</el-input>
			</div>
			<el-card shadow='hover' class='dHSwDH' v-for="(item,index) in articleData" :key='index' v-scroll-reveal.reset>
				<h2 class="title">{{item.title}}</h2>
				<div class="article_info">
					<div class="time">
						<span class="el-icon-date" style="color: #00B894;"></span> {{item.birth_time}}
					</div>
					<span class="tag_item" v-for="(item2,index2) in item.tags" :style="item2.color"
						:key='index2'>{{item2.name}}</span>
					<div class="readingCount"> <span class="el-icon-hot-water" style="color: red;font-size: 18px;"></span>
						{{item.hot}}</div>
					<div class="commentCount"> <span class="el-icon-chat-dot-square" style="font-size: 18px;"></span>
						{{item.remark_num}}
					</div>
				</div>
				<div class="image_box">
					<img v-lazy="item.img_src" @click='goArticleDetail(item.path)' alt="这是一张图片">
				</div>
				<div class="des">{{item.end_text}}</div>
			</el-card>
			<el-pagination @current-change="handleCurrentChange" :page-size="8" layout="total,prev, pager, next, jumper"
				:total="totals"   :current-page.sync="currentPage" style='text-align: center;' class='asJSlk'>
			</el-pagination>
		</div>
		<el-dialog title="查询结果" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
			<span> <el-link :href="queryUrl" target="_self" type="primary">{{queryResult}}</el-link></span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">Cancel</el-button>
				<el-button type="primary" @click="dialogVisible = false">Ok</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import MainBodySkeleton from './mainbodySkeleton.vue';
	import {
		clientHome,
		clientArticleQuery
	} from '../../server/api/index.js';
	export default {
		props: {
			articleDataed: Array,
			totals: Number
		},
		data() {
			return {
				input: '',
				articleData: [],
				dialogVisible: false,
				queryResult:'',//查询结果
				queryUrl:'',//路由地址
				loading:false,
				currentPage:this.$store.state.TruePage,
			}
		},
		methods: {
			async hanldePagination(page) {
				window.scrollTo(0, 0);
				this.$store.state.TruePage = page;
				this.loading = true;
				let ref = await clientHome({
					page
				});
				if (ref.code === 200) {
					ref.data.forEach((val) => {
						val.tags = JSON.parse(val.tags).tagData; //数据类型转换
						val.tags.forEach((val) => {
							val.color = 'background-color:' + val.color; //属性值转换
						})
					});
					this.articleData = ref.data;
					this.loading = false;
				}
			},
			handleCurrentChange(page) {      // 此为翻页按钮
			    window.scrollTo(0, 0);
				this.hanldePagination(page); // 翻页
			},
			goArticleDetail(path) {
				window.scrollTo(0, 0);
				return this.$router.push({path: path});
			},
			handleClose(done) {
				this.$confirm('确认关闭？')
					.then(_ => {
						done();
					})
					.catch(_ => {});
			},
			async startQuery() {
				let query = this.input;
				if (query.length === 0) return this.$myMessage({msg:'查询字段不能为空!',icon:'el-icon-error',color:'color:red;',duration:2000});;
				let ref = await clientArticleQuery({
					query
				});
				if (ref.code === 200) {
				   this.queryResult = ref.data[0].title;
				   this.queryUrl = '#'+ref.data[0].path;
				   this.dialogVisible = true;
				   this.input = '';
				}else{
					this.queryResult = ref.data;
					this.dialogVisible = true;
					this.input = '';
				}
			},
		},
		created() {
			this.articleData = this._props.articleDataed; //将父组件传来的值进行拷贝一份
		},
		components:{
			MainBodySkeleton
		},
		mounted() {
			this.hanldePagination(this.currentPage);//初始化
		}
	}
</script>

<style scoped="scoped">
	.home_content_header {
		padding: 0px 10px 5px;
		width: 100%;
		display: flex;
		-webkit-box-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		align-items: center;
		font-size: 12px;
	}

	.home_content_header .info {
		color: rgb(86, 86, 86);
		font-weight: 600;
	}

	.home_content_header .info span {
		color: red;
	}

	.searchInput {
		width: 150px;
		color:
			rgb(122, 122, 122);
		margin-right: 11px;
	}

	.dHSwDH {
		user-select: none;
		min-height: 250px;
		cursor: pointer;
		font-size: 13px;
		overflow: hidden;
		transition: all 0.5s ease 0s;
		opacity: 1;
		position: relative;
		padding: 10px 4px;
	}

	.dHSwDH .title {
		margin: 0px;
		color: rgb(24, 144, 255);
	}

	.dHSwDH .article_info {
		padding: 10px 0px;
		display: flex;
		flex-wrap: wrap;
	}

	.dHSwDH .article_info div {
		margin-right: 10px;
	}

	.image_box {
		max-height: 260px;
		cursor: pointer;
		overflow: hidden;
		border-radius: 6px;
	}

	.image_box img {
		transition: all 0.5s ease 0s;
		width: 100%;
	}

	.image_box img:hover {
		transform: scale(1.1);
	}

	.tag_item {
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		padding: 0px 10px;
		border-radius: 6px;
		margin-right: 10px;
		margin-top: 3px;
		color: white;
	}

	.des {
		color: rgb(119, 119, 119);
		font-variant: tabular-nums;
		font-size: 14px;
	}

	@media not screen and (min-width: 50em) {
		.searchInput {
			margin-top: 5px;
			margin-right:20px;
		}
	}
</style>